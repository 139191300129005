<template>
    <v-card>
        <v-dialog v-model="dialogDelete" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline red--text text--darken-1" v-text="$t('title.deletePriority')"></span>
                </v-card-title>
                <v-card-text>
                    <span class="subtitle-2 text--darken-1" v-text="$t('title.deletePriorityReplace')"></span>
                </v-card-text>

                <v-card-text>
                    <priority-selector v-model="editPriority.replace" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="cancelEdit">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn color="red" dark @click="deleteObject">
                        {{ $t('buttons.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card-text v-if="editPriority && dialog">
            <v-card flat>
                <v-card-title>
                    <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        v-model="editPriority.value"
                        :label="$t('priorities.attributes.value')"
                        required
                        color="red"
                    ></v-text-field>
                    <v-text-field
                        v-model="editPriority.label"
                        :label="$t('priorities.attributes.label')"
                        required
                        color="red"
                    ></v-text-field>
                    <v-textarea
                        v-model="editPriority.description"
                        :label="$t('priorities.attributes.description')"
                    ></v-textarea>
                    <v-color-picker
                        v-model="editPriority.color"
                        hide-canvas
                        hide-mode-switch
                        show-swatches
                        hide-inputs
                        flat
                        width="100%"
                        height="200"
                        class="mb-4"
                    ></v-color-picker>
                    <v-switch v-model="editPriority.default" :label="$t('priorities.attributes.default')"></v-switch>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="cancelEdit">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn color="success" @click="saveObject">
                        {{ editPriority.id ? $t('buttons.refresh') : $t('buttons.add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-card-text>

        <v-card-text v-else>
            <v-data-table
                :headers="headers"
                :items="priorities"
                sort-by="value"
                :items-per-page="20"
                :footer-props="{
                    'items-per-page-options': [10, 20, 50, 100, 200],
                    'show-current-page': true,
                    'show-first-last-page': true,
                }"
            >
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-btn rounded color="primary" dark @click="dialog = true">
                            {{ $t('buttons.createPriority') }}
                        </v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.color`]="{ item }">
                    <v-avatar :color="item.color" size="36"> &nbsp; </v-avatar>
                </template>
                <template v-slot:[`item.value`]="{ item }">
                    <span :class="item.default ? 'subtitle-2' : ''">{{ item.value }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import prioritySelector from '@/components/selectors/simple/priority';

import Clone from 'lodash/cloneDeep';

export default {
    name: 'settings-priorities',
    data: () => ({
        dialog: false,
        dialogDelete: false,
        editPriority: {
            color: '#000000',
        },
    }),
    created() {
        this.findPriorities();
    },
    components: {
        prioritySelector,
    },
    watch: {},
    computed: {
        ...mapGetters(['priorities']),
        headers() {
            return [
                {
                    text: '',
                    sortable: false,
                    value: 'color',
                    width: 36,
                },
                {
                    text: this.$t('priorities.attributes.value'),
                    sortable: true,
                    value: 'value',
                },
                {
                    text: this.$t('priorities.attributes.label'),
                    sortable: true,
                    value: 'label',
                },
                {
                    text: this.$t('priorities.attributes.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editPriority.id ? this.$t('title.editPriority') : this.$t('title.newPriority');
        },
    },
    methods: {
        ...mapActions(['findPriorities', 'createPriority', 'updatePriority', 'deletePriority']),
        cancelEdit() {
            this.dialog = false;
            this.dialogDelete = false;
            this.editPriority = { color: '#000000' };
        },
        editItem(item) {
            this.dialog = true;
            this.editPriority = Clone(item);
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.editPriority = Clone(item);
        },
        deleteObject() {
            this.deletePriority(this.editPriority);
            this.cancelEdit();
        },
        saveObject() {
            if (this.editPriority.id) {
                this.updatePriority(this.editPriority);
            } else {
                this.createPriority(this.editPriority);
            }
            this.cancelEdit();
        },
    },
};
</script>

<style></style>
